/**
 *  A simple helper function to naively push values to the dataLayer.
 *  Note that while this function is stateless, it is not pure as it mutates the
 *  dataLayer variable in the global namespace.
 *  @param {Object} payload - the payLoad to be pushed to the dataLayer.
 *  @param {string} [dlName=dataLayer] - the name of the dataLayer you want to target, defaults to `dataLayer`.
 *  @returns {boolean} - `true` if successful, `false` if not.
 */
export default (payload, dlName = 'dataLayer') => {
  if (
    isInvalidType(payload, {}) ||
    isEmptyObject(payload) ||
    isInvalidType(dlName, '')
  )
    return false;

  window[dlName] = window[dlName] || [];
  window[dlName].push(payload);
  return true;
};

const isEmptyObject = (inspectObj) => {
  if (Object.entries(inspectObj).length) return false;
  console.warn(`GTM Pusher: Object must contain at least one key/value`);
  return true;
};

const isInvalidType = (inspectTarget, typeTarget) => {
  const inputType = Object.prototype.toString.call(inspectTarget);
  const targetType = Object.prototype.toString.call(typeTarget);

  const rxTrueType = /\[object (\w+)\]/;

  if (inputType === targetType) return false;

  console.warn(
    `GTM Pusher: Expected type ${
      targetType.match(rxTrueType)[1]
    } but got type ${inputType.match(rxTrueType)[1]}`
  );
  return true;
};
