import React from 'react';
import styled from '@emotion/styled';
import Layout from './layout';
import SEO from './seo';
import SliceZone from './slice-zone';
import LazyImage from './lazy-image';
import BlogRelated from '../blocks/blog/blogRelated';
import Label from '../components/Label';
import Helmet from 'react-helmet';
import convertHtml from '../utils/convertHtml';
import BlogHeader from '../blocks/blog/blogHeader';
import AuthorBio from '../blocks/blog/authorBio';

const BlogUpperHeaderContainer = styled.div`
  position: relative;
  max-height: 900px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-top: ${props => props.height};

  @media (max-width: 1025px) {
    flex-wrap: wrap;
    height: calc(100vh - (${props => props.height}));
  }
`;

const BlogHeaderImageContainer = styled.div`
  width: 50%;
  height: calc(100% - 120px);
  @media (max-width: 1025px) {
    width: 100%;
    height: 50vh;
  }
`;

const BlogHeaderImage = styled.div`
  width: 100%;
  height: 100%;
`;

const BlogHeaderTitleContainerOuter = styled.div`
  width: 50%;
  min-height: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 1025px) {
    width: 100%;
    height: 50vh;
  }
`;

const BlogHeaderTitleContainer = styled.div`
  width: 75%;
  max-width: 1440px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BlogHeaderTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 61px;
  text-align: left;
  display: flex;
  align-self: flex-start;
  color: #ffffff;
  max-width: 75%;
  margin-top: 16px;

  @media screen and (max-width: 63.9375em) {
    font-size: 40px;
    line-height: 47px;
  }
`;

const BlogHeaderSubTitle = styled.div`
  font-weight: bold;
  color: ${props => props.color};
  font-weight: 800;
  font-size: 21px;
  line-height: 1.5;
  margin-top: 16px;
  width: 100%;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    font-size: 18px;
    line-height: 1.3;
    margin-top: 11px;
  }
`;

const BlogLowerHeaderContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const BlogLowerHeaderContainer = styled.div`
  width: 100%;
`;

const AuthorInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    width: 90%;

    margin-left: 5%;
  }
`;

const AuthorImageContainer = styled.div`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  overflow: hidden;
  @media (max-width: 750px) {
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
`;

const ReadTime = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.color};
  margin-bottom: 35px;
  margin-left: 30px;
  @media (max-width: 750px) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2.5px;
    margin-left: 15px;
  }
`;

const MetadataContainer = styled.div`
  @media (max-width: 750px) {
    width: calc(100% - 70px);
  }
`;

const AuthorName = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 159.8%;
  margin-left: 30px;
  max-width: 350px;
  color: rgba(0, 0, 0, 0.7);
  strong {
    font-weight: bold;
  }
  @media (max-width: 750px) {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 2.5px;
    margin-left: 15px;
  }
`;

const PostDate = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 30px;
  line-height: 159.8%;
  color: #aaaaaa;
  @media (max-width: 750px) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2.5px;
    margin-left: 15px;
  }
`;

function AnyPageContent({
  id,
  title,
  seo_title,
  url,
  description,
  sharingImage,
  blogPost,
  featuredImage,
  featuredVideo,
  postColorTheme,
  authorImage,
  author,
  postDate,
  authorBio,
  CustomTemplateComponent,
  data,
  body,
  open_graph_title,
  open_graph_description,
  open_graph_type,
  open_graph_image,
  logo,
  fixed_logo,
  mobile_logo,
  fixed_mobile_logo,
  menu_logo,
  menu_background,
  display_review_bar,
  header_background_color,
  header_items_color,
  mobile_header_items_color,
  sliceMappings,
  customData,
  seo_description,
  props,
  ...rest
}) {
  return (
    <Layout
      data-wio-id={id}
      {...rest}
      menu_background={menu_background}
      logo={logo}
      fixed_menu_logo={fixed_logo}
      fixed_mobile_logo={fixed_mobile_logo}
      header_items_color={header_items_color}
      mobile_logo={mobile_logo}
      display_review_bar={display_review_bar}
    >
      <SEO
        title={seo_title}
        description={seo_description.text}
        sharingImage={sharingImage}
      />
      {blogPost === true ? (
        <React.Fragment>
          <Helmet>
            <meta
              property="og:url"
              content={'https://reloaddigital.co.uk' + url}
            />
            <meta property="og:type" content={open_graph_type} />
            <meta property="og:title" content={open_graph_title} />
            <meta property="og:description" content={open_graph_description} />
            <meta property="og:image" content={open_graph_image} />
          </Helmet>

          <BlogHeader
            labelText={customData.post_category}
            color={postColorTheme}
            subtitle={customData.subtitle.text}
            title={title}
            image={featuredImage}
            video={featuredVideo}
            customData={customData}
            authorImage={authorImage}
            author={author.text}
            postDate={postDate}
            authorBio={authorBio}
            body={body}
          />
        </React.Fragment>
      ) : null}
      {CustomTemplateComponent && (
        <CustomTemplateComponent title={title} data={data} body={body} />
      )}
      {body && (
        <SliceZone
          body={body}
          customData={customData}
          color={postColorTheme}
          sliceMappings={sliceMappings}
        />
      )}
      {blogPost === true ? (
        <>
          <AuthorBio
            image={authorImage}
            author={author.text}
            bio={authorBio}
          ></AuthorBio>
          <BlogRelated
            category={customData.post_category}
            color={postColorTheme}
            customData={customData}
            url={url}
          />
        </>
      ) : null}
    </Layout>
  );
}

export default AnyPageContent;
