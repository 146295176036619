import React, { useEffect } from 'react';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import AnyPageContent from './any-page-content';
import { graphql } from 'gatsby';

import { IS_BROWSER } from '../utils/constants';
import { BLOG_SLICE_MAPPINGS } from '../utils/slice-mappings';
import gtmDispatchPush from '../utils/gtmDispatchPush';

function Blog({ data: staticData, ...rest }) {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;

  const data = mergePrismicPreviewData({ staticData, previewData });

  const {
    prismicBlog: {
      id,
      url,
      title: title,
      data: blogData,
      data: { body },
    },
  } = data;

  console.log(blogData);

  useEffect(() => {
    gtmDispatchPush({ event: 'blogView', blogData });
  }, []);

  return (
    <div>
      <AnyPageContent
        id={id}
        url={url}
        title={blogData.blog_title.text}
        seo_title={blogData.seo_title.text}
        customData={blogData}
        body={body}
        subtitle={blogData.subtitle}
        featuredImage={blogData.featured_image}
        featuredVideo={blogData.featured_video}
        postColorTheme={blogData.post_color_theme}
        authorImage={blogData.author_image}
        author={blogData.post_author}
        open_graph_type={blogData.open_graph_type.text}
        open_graph_title={blogData.open_graph_title.text}
        open_graph_description={blogData.open_graph_description.text}
        open_graph_image={blogData.open_graph_image.url}
        postDate={blogData.post_date}
        authorBio={blogData.author_bio}
        logo={blogData.logo}
        fixed_logo={blogData.fixed_logo}
        mobile_logo={blogData.mobile_logo}
        fixed_mobile_logo={blogData.fixed_mobile_logo}
        menu_logo={blogData.menu_logo}
        menu_background={blogData.menu_background}
        display_review_bar={blogData.display_review_bar}
        header_background_color={blogData.header_background_color}
        header_items_color={blogData.header_items_color}
        mobile_header_items_color={blogData.mobile_header_items_color}
        sliceMappings={BLOG_SLICE_MAPPINGS}
        blogPost={true}
        seo_description={blogData.seo_description}
      />
    </div>
  );
}

export const query = graphql`
  query BlogQuery($id: String) {
    prismicBlog(id: { eq: $id }) {
      ...Blog
    }
  }
`;

export default Blog;
